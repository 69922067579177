export interface Document {
  documentId: string;
  name: string;
  s3Key: string;
  type: 'pdf' | 'image';
  date: string;
  category?: string;
  description?: string;
  fileName: string;
}

export interface DocumentFile {
  file: File;
  description: string | null;
  category?: string;
  date: string;
  name: string;
}

export enum DocumentCategory {
  REPORTS = 'reports',
  CERTIFICATES = 'certificates',
  CONSTRUCTION = 'construction',
  MEASUREMENTS = 'measurements',
  OTHER = 'other'
}

export interface CreateDocument {
  document: DocumentFile;
  propertyId: string;
}

export interface DeleteDocument {
  s3Key: string;
  documentId: string;
}
