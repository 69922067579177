import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormGroup, TextField } from '@mui/material';
import { AddedDocuments } from './AddedDocuments';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { addRenovation } from '../../pages/renovation/renovationSlice';
import { Renovation, RenovationFormModel, requiredFields } from '../../models/renovation';
import { DocumentFile } from '../../models/document';
import { ChangeLog } from '../../models/common';
import { dateToFormFormat } from '../../utils';
import './styles.css';
import { selectSelectedProperty } from '../../pages/property/propertySlice';
import { FormButton } from '../buttons/FormButton';

interface RenovationFormProps {
  handleClose: () => void;
}

export const RenovationForm = ({ handleClose }: RenovationFormProps) => {
  const { t } = useTranslation();
  const [renovationData, setRenovationData] = useState<RenovationFormModel>({
    renovation: '',
    constructor: null,
    changeLog: null,
    startDate: new Date().toString(),
    documents: null
  });

  const dispatch = useAppDispatch();
  const selectedProperty = useAppSelector(selectSelectedProperty);

  const handleChange = (key: keyof Renovation, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newValue: string | Date | ChangeLog[] = event.target.value;
    if (key === 'startDate') {
      // @ts-ignore
      newValue = newValue ? new Date(`${event.target.value}T23:23:59`) : null;
    }

    if (key === 'changeLog') {
      // @ts-ignore
      newValue = newValue ? [{ updatedAt: new Date().toString(), description: event.target.value }] : null;
    }
    setRenovationData({ ...renovationData, [key]: newValue });
  };

  const handleSave = async () => {
    if (renovationData) {
      await dispatch(addRenovation({
        renovation: renovationData,
        propertyId: selectedProperty?.propertyId
      }));
      handleClose();
    }
  };

  const handleFileSelection = (fileList: FileList | null) => {
    const { documents: stateDocuments } = renovationData;
    if (fileList && fileList.length > 0) {
      const documents: DocumentFile[] = [];
      for (let i = 0; i < fileList.length; i += 1) {
        documents.push({
          file: fileList[i],
          description: null,
          date: new Date().toString(),
          name: fileList[i].name
        });
      }
      setRenovationData({
        ...renovationData,
        documents: stateDocuments ? [...stateDocuments, ...documents] : documents
      });
    }
  };

  const handleNameChange = (value: string, file: File | null) => {
    if (renovationData.documents) {
      const newDocuments = renovationData.documents.map(document => (
        document.file?.name === file?.name ? { ...document, name: value } : document
      ));
      setRenovationData({
        ...renovationData,
        documents: newDocuments
      });
    }
  };

  const handleDescription = (value: string, file: File | null) => {
    if (renovationData.documents) {
      const newDocuments = renovationData.documents.map(document => (
        document.file?.name === file?.name ? { ...document, description: value } : document
      ));
      setRenovationData({
        ...renovationData,
        documents: newDocuments
      });
    }
  };

  const handleDelete = (document: DocumentFile) => {
    const documents = renovationData.documents?.filter(doc => doc.file?.name !== document.file?.name);
    setRenovationData({
      ...renovationData,
      documents: documents || []
    });
  };

  return (
    <FormGroup className="form-container">
      <Box className="form-content-container">
        <TextField
          label={t('common.renovation')}
          type="text"
          className="form-field"
          InputLabelProps={{ shrink: true }}
          onChange={evt => handleChange('renovation', evt)}
          required
          defaultValue={renovationData.renovation || ''}
        />
        <TextField
          label={t('renovation.constructor')}
          type="text"
          className="form-field"
          InputLabelProps={{ shrink: true }}
          onChange={evt => handleChange('constructor', evt)}
          defaultValue={renovationData.constructor || ''}
        />
        <TextField
          label={t('common.note')}
          type="text"
          className="form-field"
          InputLabelProps={{ shrink: true }}
          onChange={evt => handleChange('changeLog', evt)}
          defaultValue={renovationData.changeLog || ''}
          multiline
          required
        />
        <TextField
          label={t('dateTime.startDate')}
          type="date"
          className="form-field"
          onBlur={evt => handleChange('startDate', evt)}
          defaultValue={dateToFormFormat(renovationData.startDate || '')}
          InputLabelProps={{ shrink: true }}
          required
        />
        <AddedDocuments
          acceptedFiles="image/*,application/pdf"
          documents={renovationData?.documents || []}
          handleSelectFile={handleFileSelection}
          handleNameChange={handleNameChange}
          handleDescription={handleDescription}
          handleDelete={handleDelete}
        />
      </Box>
      <FormButton handleSave={handleSave} formData={renovationData} requiredFields={requiredFields} />
    </FormGroup>
  );
};
