import { ChangeLog } from './common';
import { DocumentFile } from './document';

export interface Renovation {
  renovationId: string;
  propertyId: string;
  renovation: string;
  constructor: string;
  startDate: string;
  changeLog: ChangeLog[];
  documents: string[];
}

export interface RenovationFormModel {
  renovation: string;
  constructor: string | null;
  startDate: string;
  changeLog: ChangeLog[] | null;
  documents: DocumentFile[] | null;
}

export interface CreateRenovation {
  renovation: RenovationFormModel,
  propertyId?: string
}

export interface UpdateRenovation {
  renovation: Renovation,
  propertyId?: string
  document?: DocumentFile
}

export const requiredFields = ['renovation', 'changeLog', 'startDate'];
