import {
  ChangeEvent, useRef, useState
} from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';
import { DocumentCategory, DocumentFile } from '../../models/document';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectIsMobile } from '../../app/appSlice';
import { addDocument, selectDocumentsLoading } from '../../pages/document/documentSlice';
import { Property } from '../../models/property';
import { SecondaryContent } from '../pageContent/SecondaryContent';
import { Loading } from '../loading/Loading';
import { FormButton } from '../buttons/FormButton';

interface NewDocumentProps {
  selectedProperty?: Property;
  setAddNewFile: (status: boolean) => void;
}

export const NewDocument = ({ selectedProperty, setAddNewFile }: NewDocumentProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const isMobile = useAppSelector(selectIsMobile);
  const loading = useAppSelector(selectDocumentsLoading);
  const dispatch = useAppDispatch();

  const [newDocument, setNewDocument] = useState<DocumentFile>();

  const handleFileSelection = (fileList: FileList | null) => {
    if (fileList && fileList.length > 0) {
      setNewDocument({
        name: fileList[0].name,
        file: fileList[0],
        description: null,
        date: new Date().toString()
      });
    }
  };

  const handleSave = async () => {
    if (newDocument && selectedProperty) {
      await dispatch(addDocument({
        document: newDocument,
        propertyId: selectedProperty.propertyId
      }));
      setNewDocument(undefined);
      setAddNewFile(false);
    }
  };

  if (loading) {
    return <SecondaryContent title=""><Loading /></SecondaryContent>;
  }

  if (!newDocument) {
    return isMobile ? (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Box>
          <Button
            startIcon={<Add />}
            onClick={() => inputRef?.current?.click()}
            className="basic-button"
          >
            {t('button.addFile')}
          </Button>
          <input
            ref={inputRef}
            type="file"
            accept="application/pdf, image/*"
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleFileSelection(event.target.files)}
            hidden
          />
        </Box>
      </Box>
    ) : (
      <SecondaryContent title="">
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Box>
            <Button
              startIcon={<Add />}
              onClick={() => inputRef?.current?.click()}
              className="basic-button"
            >
              {t('button.addFile')}
            </Button>
            <input
              ref={inputRef}
              type="file"
              accept="application/pdf, image/*"
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFileSelection(event.target.files)}
              hidden
            />
          </Box>
        </Box>
      </SecondaryContent>
    );
  }

  return isMobile ? (
    <Box className="form-container">
      <Box className="form-content-container">
        {newDocument && (
          <>
            <TextField
              sx={{ width: '100%', marginTop: 1, marginBottom: 1 }}
              label={t('common.name')}
              value={newDocument.name || ''}
              type="text"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={({ target }) => setNewDocument({ ...newDocument, name: target.value })}
              required
            />
            <TextField
              className="form-field"
              label={t('common.category')}
              InputLabelProps={{ shrink: true }}
              value={newDocument?.category || ''}
              onChange={({ target }) => setNewDocument({ ...newDocument, category: target.value })}
              select
              required
            >
              {Object.values(DocumentCategory).map(value => (
                <MenuItem
                  value={value}
                  key={value}
                >
                  {t(`document.${value}`)}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={{ width: '100%', marginTop: 2 }}
              label={t('document.description')}
              value={newDocument?.description || ''}
              type="text"
              variant="outlined"
              minRows={isMobile ? 1 : 3}
              InputLabelProps={{ shrink: true }}
              onChange={({ target }) => setNewDocument({ ...newDocument, description: target.value })}
              multiline
            />
          </>
        )}
      </Box>
      {newDocument && (
        <Box display="flex">
          <FormButton
            formData={newDocument}
            requiredFields={['name', 'category']}
            handleSave={handleSave}
          />
        </Box>
      )}
    </Box>
  ) : (
    <SecondaryContent title={t('document.newFile')}>
      <Box className="form-container">
        <Box className="form-content-container">
          {newDocument && (
          <>
            <TextField
              sx={{ width: '100%', marginTop: 1, marginBottom: 1 }}
              label={t('common.name')}
              value={newDocument?.name != null ? newDocument.name : newDocument.file.name}
              type="text"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={({ target }) => setNewDocument({ ...newDocument, name: target.value })}
              required
            />
            <TextField
              className="form-field"
              label={t('common.category')}
              InputLabelProps={{ shrink: true }}
              value={newDocument?.category || ''}
              onChange={({ target }) => setNewDocument({ ...newDocument, category: target.value })}
              select
              required
            >
              {Object.values(DocumentCategory).map(value => (
                <MenuItem
                  value={value}
                  key={value}
                >
                  {t(`document.${value}`)}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={{ width: '100%', marginTop: 1 }}
              label={t('document.description')}
              value={newDocument?.description || ''}
              type="text"
              variant="outlined"
              minRows={isMobile ? 1 : 3}
              InputLabelProps={{ shrink: true }}
              onChange={({ target }) => setNewDocument({ ...newDocument, description: target.value })}
              multiline
            />
          </>
          )}
        </Box>
        {newDocument && (
          <Box display="flex">
            <FormButton
              formData={newDocument}
              requiredFields={['name', 'category']}
              handleSave={handleSave}
            />
          </Box>
        )}
      </Box>
    </SecondaryContent>
  );
};
