import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import type { RootState } from '../../store';
import API from '../../api/api';
import { CreateDocument, DeleteDocument, Document } from '../../models/document';
import { Error } from '../../models/common';
import { generateDocumentIds, parseError } from '../../utils';
import { updateError } from '../../app/appSlice';
import deleteFromS3 from '../../aws/api/deleteFromS3';

export const fetchDocuments = createAsyncThunk<Document[], string | undefined, { rejectValue: Error }>(
  'document/fetchDocuments',
  async (propertyId, { rejectWithValue }) => {
    try {
      const response: Document[] = await API.get(`documents/${propertyId}`);
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchDocument = createAsyncThunk<Document, string, { rejectValue: Error }>(
  'document/fetchDocument',
  async (documentId, { rejectWithValue }) => {
    try {
      const response: Document = await API.get(`equipment/${documentId}`);
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const addDocument = createAsyncThunk<undefined, CreateDocument, { rejectValue: Error }>(
  'document/addDocument',
  async ({ document, propertyId }, { rejectWithValue, dispatch }) => {
    try {
      await generateDocumentIds([document], propertyId);
      dispatch(fetchDocuments(propertyId));
      return undefined;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const updateDocument = createAsyncThunk<Document, Document, { rejectValue: Error }>(
  'document/updateDocument',
  async (document, { rejectWithValue }) => {
    try {
      const clone = (({ documentId, ...o }) => o)(document);
      const response: Document = await API.put(
        `document/${document.documentId}`,
        clone
      );
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const deleteDocument = createAsyncThunk<string, DeleteDocument, { rejectValue: Error }>(
  'document/deleteDocument',
  async ({ s3Key, documentId }, { rejectWithValue }) => {
    try {
      await API.delete(`document/${documentId}`);
      await deleteFromS3(s3Key);
      return documentId;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

// Define a type for the slice state
interface DocumentState {
  documents: Document[];
  loading: boolean;
}

// Define the initial state using that type
const initialState: DocumentState = {
  documents: [],
  loading: false
};

export const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchDocuments.pending, state => {
        state.loading = true;
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        state.loading = false;
        state.documents = action.payload;
      })
      .addCase(fetchDocuments.rejected, (state, action) => {
        state.loading = false;
        updateError(action.payload);
      })
      .addCase(fetchDocument.pending, state => {
        state.loading = true;
      })
      .addCase(fetchDocument.fulfilled, state => {
        state.loading = false;
      })
      .addCase(fetchDocument.rejected, (state, action) => {
        state.loading = false;
        updateError(action.payload);
      })
      .addCase(addDocument.pending, state => {
        state.loading = true;
      })
      .addCase(addDocument.fulfilled, state => {
        state.loading = false;
        toast.success(i18next.t('document.addedSuccessfully'));
      })
      .addCase(addDocument.rejected, (state, action) => {
        state.loading = true;
        updateError(action.payload);
      })
      .addCase(updateDocument.pending, state => {
        state.loading = true;
      })
      .addCase(updateDocument.fulfilled, (state, action) => {
        state.loading = false;
        const updatedIndex = state.documents.findIndex(({ documentId }) => documentId === action.payload.documentId);
        if (updatedIndex !== -1) {
          state.documents[updatedIndex] = action.payload;
        }
      })
      .addCase(updateDocument.rejected, (state, action) => {
        state.loading = false;
        updateError(action.payload);
      })
      .addCase(deleteDocument.pending, state => {
        state.loading = true;
      })
      .addCase(deleteDocument.fulfilled, (state, action) => {
        state.loading = false;
        const removeIndex = state.documents.findIndex(({ documentId }) => documentId === action.payload);
        if (removeIndex !== -1) {
          state.documents.splice(removeIndex, 1);
        }
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        state.loading = false;
        updateError(action.payload);
      });
  }
});

export const selectDocuments = (state: RootState) => state.document.documents;
export const selectDocumentsLoading = (state: RootState) => state.document.loading;

export default documentSlice.reducer;
