import { useEffect, useRef, useState } from 'react';
import { Box, IconButton, Slide } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ImageCard } from './ImageCard';
import { Document } from '../../models/document';
import './style.css';

interface ImageCarouselProps {
  images: Document[];
  handleSelectImage: (image: Document) => void;
}

type SlideDirection = 'right' | 'left' | undefined;

export const ImageCarousel = ({ images, handleSelectImage }: ImageCarouselProps) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [slideDirection, setSlideDirection] = useState<SlideDirection>('left');
  const [cardsPerPage, setCardsPerPage] = useState<number>(5);
  const contarinerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (contarinerRef.current) {
      // Get cards per page when card with is 100px
      const tempCardsPerPage = Math.floor(contarinerRef.current.offsetWidth / 100);
      setCardsPerPage(tempCardsPerPage);
    }
  }, [contarinerRef.current]);

  const handleNextPage = () => {
    setSlideDirection('left');
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    setSlideDirection('right');
    setCurrentPage(prevPage => prevPage - 1);
  };

  return (
    <Box className="carousel-container" ref={contarinerRef}>
      <IconButton
        onClick={handlePrevPage}
        className="icon-button"
        disabled={currentPage === 0}
      >
        <NavigateBeforeIcon />
      </IconButton>
      <Box className="cards-container">
        {images.map((img, index) => (
          <Box key={img.documentId} display={currentPage === index ? 'block' : 'none'}>
            <Slide direction={slideDirection} in={currentPage === index}>
              <Box className="card">
                {images.slice(
                  index * cardsPerPage,
                  index * cardsPerPage + cardsPerPage
                ).map(image => (
                  <ImageCard key={image.documentId} image={image} handleSelectImage={handleSelectImage} />
                ))}
              </Box>
            </Slide>
          </Box>
        ))}
      </Box>
      <IconButton
        onClick={handleNextPage}
        className="icon-button"
        disabled={currentPage >= Math.ceil((images.length || 0) / cardsPerPage) - 1}
      >
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );
};
