import { MouseEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { pages } from '../../houseData';
import './TopNavBar.css';
import { LogoContainer } from '../logoContainer/LogoContainer';
import { PropertyMenu } from '../propertyMenu/PropertyMenu';
import { useLogOut } from '../../hooks/useLogOut';
import { useAppSelector } from '../../hooks';
import { selectAccount } from '../../app/appSlice';

export const TopNavBar = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const logOut = useLogOut();
  const location = useLocation();
  const { pathname } = location;
  const account = useAppSelector(selectAccount);

  const handleNavigation = (url: string) => {
    navigate(url);
    setAnchorEl(null);
  };

  const validateUser = (isAdmin?: boolean) => (!isAdmin || (isAdmin && account?.groups?.includes('admin')));

  return (
    <AppBar position="static" className="appbar">
      <Toolbar className="toolbar">
        <LogoContainer showMenu />
        <Box>
          <IconButton className="menu-button" onClick={(event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}>
            <MenuIcon />
          </IconButton>
          <Menu
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            className="menu-container"
          >
            {/* TODO: Figure out better design for this */}
            <Box marginLeft="10px">
              <PropertyMenu />
            </Box>
            <Box className="separator" />
            {pages.map(page => validateUser(page.isAdmin) && (
              <MenuItem key={page.label} onClick={() => handleNavigation(page.url)}>
                <ListItemIcon>
                  {page.icon}
                </ListItemIcon>
                <ListItemText className={pathname === page.url ? 'selected' : ''}>
                  {t(`common.${page.label}`)}
                </ListItemText>
              </MenuItem>
            ))}
            <Box className="separator" />
            <MenuItem onClick={() => handleNavigation('/profiili')}>
              <Typography variant="body2">{t('common.profile')}</Typography>
            </MenuItem>
            <MenuItem onClick={logOut}>
              <Typography variant="body2">{t('menu.logout')}</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
