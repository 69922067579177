import { DocumentFile } from './document';

export interface Equipment {
  equipmentId: string;
  propertyId: string;
  name: string;
  category: EquipmentCategory;
  purchaseDate: string;
  trademark: string;
  model: string;
  location: string;
  serialnumber: string;
  documents: string[];
}

export interface EquipmentFormModel {
  name: string | null;
  category: EquipmentCategory | null;
  purchaseDate: string | null;
  trademark: string | null;
  model: string | null;
  location: string | null;
  serialnumber: string | null;
  documents: DocumentFile[] | null;
}

export enum EquipmentCategory {
  HOUSEHOLD_APPLIANCE_FIXED = 'householdApplianceFixed',
  HOUSEHOLD_APPLIANCE_OWN = 'householdApplianceOwn',
  ELECTRICAL_APPLIANCE = 'electricalAppliance',
  HVAC = 'hvac',
  DECORATION_MATERIAL = 'decorationMaterial',
  OTHER = 'other'
}

export enum HouseholdApplianceTypes {
  DISHWASHER = 'diswasher',
  FRIDGE = 'fridge',
  FREEZER = 'freezer',
  STOVE = 'stove',
  OVEN = 'oven',
  COOKER_HOOD = 'cookerHood',
  MICRO = 'micro',
  IV_MACHINE = 'ivMachine',
  HOT_WATER_HEATER = 'hotWaterHeater',
  HEATER = 'heater'
}

export enum ElecticaldApplianceTypes {
  LIGHT = 'light'
}

export enum WaterApplianceTypes {
  SINK = 'sink'
}

export enum SurfaceMaterials {
  FLOOR = 'floor',
  WALL = 'wall',
  CEILING = 'ceiling'
}

export interface CreateEquipment {
  equipment: EquipmentFormModel;
  propertyId?: string;
}

export interface UpdateEquipment {
  newEquipment: EquipmentFormModel;
  equipmentId?: string;
  propertyId: string;
  oldDocuments?: string[];
}

export const requiredFields = ['category', 'name', 'location', 'purchaseDate'];
