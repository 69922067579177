import { DocumentFile } from './document';

export interface Maintenance {
  maintenanceId: string;
  propertyId: string;
  maintenanceType: MaintenanceType;
  maintenanceDate: string;
  description: string;
  nextMaintenance: string;
  reminder?: boolean;
  documents: string[];
}

export interface MaintenanceFormModel {
  maintenanceType: MaintenanceType | string | null;
  maintenanceDate: string;
  description: string | null;
  nextMaintenance: string | null;
  reminder: boolean;
  documents: DocumentFile[] | null;
}

export enum MaintenanceType {
  EXTERIOR_PAINTING = 'exteriorPainting',
  VENTILATION_MACHINE = 'ventilationMachine',
  IV_CHANNEL_SWEEPING = 'ivChannelSweeping',
  HEATING_SYSTEM = 'heatingSystem',
  RANGE_HOOD_FILTERS = 'rangeHoodFilters',
  FIREPLACE_SWEEPING = 'fireplaceSweeping',
  INTERCEPTOR_CLEANING = 'interceptorCleaning',
  FIRE_ALARM_INSPECTION = 'fireAlarmInspection',
  SAUNA_STOVE_TONE_CHANGE = 'saunaStoveStoneChange',
  SILICONE_REPLACEMENT = 'siliconeReplacement',
  SAUNA_TREATMENT = 'saunaTreatment',
  TERRACE_TREATMENT = 'terraceTreatment',
  DRAINPIPE_CLEANING = 'drainpipeCleaning'
}

export interface CreateMaintenance {
  maintenance: MaintenanceFormModel;
  propertyId?: string
}

export interface UpdateMaintenance {
  newMaintenance: MaintenanceFormModel;
  maintenanceId?: string;
  propertyId: string;
  oldDocuments?: string[];
}
// maintenance interval times as months
export const maintenanceInterval: number[] = [1, 2, 3, 6, 12, 24, 60];
export const requiredFields = ['maintenanceType', 'description', 'nextMaintenance'];
