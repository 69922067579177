import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Typography } from '@mui/material';
import {
  ModalContainer,
  PrimaryContent,
  RenovationForm,
  SecondaryContent
} from '../../components';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  fetchRenovations, selectRenovations, selectRenovationsLoading
} from './renovationSlice';
import { selectSelectedProperty } from '../property/propertySlice';
import { selectIsMobile } from '../../app/appSlice';
import { Renovation } from '../../models/renovation';
import { RenovationTable } from './RenovationTable';
import { RenovationDetails } from './RenovationDetails';
import './Renovation.css';

export const RenovationPage = () => {
  const { t } = useTranslation();
  const [addNew, setAddNew] = useState<boolean>(false);
  const [selectedRenovation, setSelectedRenovation] = useState<Renovation>();
  const isMobile = useAppSelector(selectIsMobile);
  const renovationLoading = useAppSelector(selectRenovationsLoading);
  const selectedProperty = useAppSelector(selectSelectedProperty);
  const renovations: Renovation[] = useAppSelector(selectRenovations);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchRenovations(selectedProperty?.propertyId));
  }, [selectedProperty]);

  useEffect(() => {
    if (selectedRenovation) {
      const currentRenovation = renovations.find(({ renovationId }) => renovationId === selectedRenovation.renovationId);
      setSelectedRenovation(currentRenovation);
    }
  }, [renovations]);

  return (
    <>
      <PrimaryContent
        title={t('common.renovations')}
        subTitle={addNew ? t('renovation.new') : undefined}
        isLoading={renovationLoading}
        button={{
          handleClick: () => setAddNew(!addNew),
          text: t(`button.${addNew ? 'cancel' : 'addNew'}`),
          icon: addNew ? <CloseOutlinedIcon /> : <AddOutlinedIcon />
        }}
      >
        <>
          {addNew && <RenovationForm handleClose={() => setAddNew(false)} />}
          {!addNew && renovations.length > 0 && (
            <RenovationTable
              renovations={renovations}
              selectedRenovation={selectedRenovation}
              setSelectedRenovation={setSelectedRenovation}
            />
          )}
          {!addNew && !renovations.length && <Typography>{t('renovation.noRenovations')}</Typography>}
        </>
      </PrimaryContent>
      {!isMobile && !addNew && (
        <SecondaryContent title={selectedRenovation ? selectedRenovation.renovation : ''}>
          <RenovationDetails selectedRenovation={selectedRenovation} />
        </SecondaryContent>
      )}
      {isMobile && !addNew && (
        <ModalContainer
          title={selectedRenovation ? selectedRenovation.renovation : ''}
          open={Boolean(selectedRenovation)}
          handleClose={() => setSelectedRenovation(undefined)}
        >
          <RenovationDetails selectedRenovation={selectedRenovation} />
        </ModalContainer>
      )}
    </>
  );
};
