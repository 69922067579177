import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import CoffeeMakerOutlinedIcon from '@mui/icons-material/CoffeeMakerOutlined';
import FormatPaintOutlinedIcon from '@mui/icons-material/FormatPaintOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  ConstructionForm, EquipmentForm, LogoContainer, MaintenanceForm, PrimaryContent, RenovationForm
} from '../../components';
import { useAppSelector } from '../../hooks';
import { selectLinkUser } from '../permission/permissionSlice';
import API from '../../api/api';
import { selectConstructionLoading } from '../construction/constructionSlice';
import { selectRenovationsLoading } from '../renovation/renovationSlice';
import { selectMaintenancesLoading } from '../maintenance/maintenanceSlice';
import { selectEquipmentsLoading } from '../equipment/equipmentSlice';

export const LinkUserPage = () => {
  const { t } = useTranslation();
  const [selectedForm, setSelectedForm] = useState<keyof typeof subTitles>();
  const [propertyAddress, setPropertyAddress] = useState<string>();
  const [subTitle, setSubTitle] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const linkUser = useAppSelector(selectLinkUser);
  const maintenanceLoading = useAppSelector(selectMaintenancesLoading);
  const renovationLoading = useAppSelector(selectRenovationsLoading);
  const equipmentLoading = useAppSelector(selectEquipmentsLoading);
  const constructionLoading = useAppSelector(selectConstructionLoading);

  const subTitles = {
    maintenance: t('maintenance.new'),
    renovation: t('renovation.new'),
    equipment: t('equipment.new'),
    construction: t('construction.newWorkPart')
  };

  useEffect(() => {
    const getProperty = async () => {
      if (linkUser) {
        try {
          setLoading(true);
          const response: string = await API.get(`link/property/${linkUser.propertyId}/${linkUser.linkId}`);
          setPropertyAddress(response);
          setLoading(false);
        } catch {
          toast.error(t('common.genericError'));
          setLoading(false);
        }
      }
    };
    getProperty();
  }, [linkUser]);

  useEffect(() => {
    if (linkUser) {
      const expirationDate = new Date(linkUser.expirationDate);
      const formattedDateTime = expirationDate.toLocaleString('fi-FI');
      setSubTitle(selectedForm ? subTitles[selectedForm] : t('common.linkUserHelp', { expirationDate: formattedDateTime }));
    } else {
      setSubTitle(selectedForm ? subTitles[selectedForm] : '');
    }
  }, [selectedForm, linkUser]);

  return (
    <Box className="login-page setup-page">
      <LogoContainer showMenu={false} />
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="80%">
        {propertyAddress && (
          <PrimaryContent
            title={propertyAddress}
            subTitle={subTitle}
            button={selectedForm ? {
              handleClick: () => setSelectedForm(undefined),
              text: t('button.cancel'),
              icon: <CloseOutlinedIcon />
            } : undefined}
            isLoading={maintenanceLoading || renovationLoading || equipmentLoading || constructionLoading || loading}
          >
            <>
              {!selectedForm && (
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" width="100%" height="100%">
                  <Button startIcon={<EngineeringOutlinedIcon />} sx={{ margin: 1 }} variant="contained" onClick={() => setSelectedForm('maintenance')}>
                    {t('common.addNewMaintenance')}
                  </Button>
                  <Button startIcon={<FormatPaintOutlinedIcon />} sx={{ margin: 1 }} variant="contained" onClick={() => setSelectedForm('renovation')}>
                    {t('common.addNewRenovation')}
                  </Button>
                  <Button startIcon={<CoffeeMakerOutlinedIcon />} sx={{ margin: 1 }} variant="contained" onClick={() => setSelectedForm('equipment')}>
                    {t('common.addNewEquipment')}
                  </Button>
                  <Button startIcon={<HandymanOutlinedIcon />} sx={{ margin: 1 }} variant="contained" onClick={() => setSelectedForm('construction')}>
                    {t('common.addNewConstruction')}
                  </Button>
                </Box>
              )}
              {selectedForm === 'maintenance' && (
                <MaintenanceForm edit={false} setEdit={() => { }} setAddNew={value => setSelectedForm(!value ? undefined : 'maintenance')} />
              )}
              {selectedForm === 'renovation' && (
                <RenovationForm handleClose={() => setSelectedForm(undefined)} />
              )}
              {selectedForm === 'equipment' && (
                <EquipmentForm edit={false} setEdit={() => { }} setAddNew={value => setSelectedForm(!value ? undefined : 'equipment')} />
              )}
              {selectedForm === 'construction' && (
                <ConstructionForm setSubTitle={setSubTitle} handleClose={() => setSelectedForm(undefined)} />
              )}
            </>
          </PrimaryContent>
        )}
      </Box>
    </Box>
  );
};
