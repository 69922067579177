import { Box, Typography } from '@mui/material';
import './Image.css';
import { useEffect, useState } from 'react';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { dateToTableFormat } from '../../utils';
import generatePreSignedUrl from '../../aws/api/generatePreSignedUrl';
import { DownloadFileButton } from '../buttons/DownloadFileButton';

interface ImageProps {
  s3Key: string;
  name: string;
  description?: string;
  date: string;
  fileName: string;
}

export const Image = ({
  s3Key, name, description, date, fileName
}: ImageProps) => {
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    const fetchUrl = async () => {
      setUrl(await generatePreSignedUrl(s3Key));
    };

    fetchUrl();
  }, [s3Key]);

  if (!url) {
    return <Box />;
  }

  return (
    <Box className="image-container">
      <Box className="image">
        <img
          src={url}
          alt={name}
          crossOrigin="anonymous"
        />
      </Box>
      <Box className="description-container">
        <Typography sx={{ fontWeight: 'bold' }}>
          {name === fileName ? '' : (
            <span style={{ marginRight: '8px' }}>
              {fileName}
            </span>
          )}
          {name === fileName ? '' : '|'}
          <span style={{ marginLeft: '8px' }}>{dateToTableFormat(date)}</span>
        </Typography>
        {description && (
          <Typography sx={{ textAlign: 'justify' }}>
            <FormatQuoteIcon fontSize="small" color="disabled" />
            {description}
            <FormatQuoteIcon fontSize="small" color="disabled" />
          </Typography>
        )}
      </Box>
      <DownloadFileButton url={url} fileName={fileName} />
    </Box>
  );
};
