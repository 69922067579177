import { useEffect, useState } from 'react';
import {
  FormGroup,
  TextField,
  MenuItem,
  Box,
  Button,
  Autocomplete
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddedDocuments } from './AddedDocuments';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  addEquipment, deleteEquipment, selectSelectedEquipment, updateEquipment,
  updateSelectedEquipment
} from '../../pages/equipment/equipmentSlice';
import {
  ElecticaldApplianceTypes,
  EquipmentCategory,
  EquipmentFormModel,
  HouseholdApplianceTypes,
  SurfaceMaterials,
  WaterApplianceTypes,
  requiredFields
} from '../../models/equipment';
import { dateToFormFormat } from '../../utils';
import './styles.css';
import { selectSelectedProperty } from '../../pages/property/propertySlice';
import { DocumentFile } from '../../models/document';
import { FormButton } from '../buttons/FormButton';
import { ConfirmContainer } from '../confirmContainer/ConfirmContainer';
import { selectDocuments } from '../../pages/document/documentSlice';

interface EquipmentFormProps {
  setAddNew: (value: boolean) => void
  edit?: boolean;
  setEdit: (value: boolean) => void
}

export const EquipmentForm = ({ setAddNew, edit, setEdit }: EquipmentFormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedProperty = useAppSelector(selectSelectedProperty);
  const selectedEquipment = useAppSelector(selectSelectedEquipment);
  const docs = useAppSelector(selectDocuments);

  const isEditing = edit && selectedEquipment;

  const [selectedName, setSelectedName] = useState<string | null>(selectedEquipment?.name ?? null);

  const [equipmentData, setEquipmentData] = useState<EquipmentFormModel>({
    name: isEditing ? selectedEquipment.name : null,
    category: isEditing ? selectedEquipment.category : null,
    purchaseDate: new Date().toString(),
    trademark: isEditing ? selectedEquipment.trademark : null,
    model: isEditing ? selectedEquipment.model : null,
    location: isEditing ? selectedEquipment.location : null,
    serialnumber: isEditing ? selectedEquipment.serialnumber : null,
    documents: null
  });

  const types = {
    [EquipmentCategory.HOUSEHOLD_APPLIANCE_FIXED]: Object.values(HouseholdApplianceTypes),
    [EquipmentCategory.HOUSEHOLD_APPLIANCE_OWN]: Object.values(HouseholdApplianceTypes),
    [EquipmentCategory.ELECTRICAL_APPLIANCE]: Object.values(ElecticaldApplianceTypes),
    [EquipmentCategory.HVAC]: Object.values(WaterApplianceTypes),
    [EquipmentCategory.DECORATION_MATERIAL]: Object.values(SurfaceMaterials),
    [EquipmentCategory.OTHER]: null
  };
  const [names, setNames] = useState<string[] | null>(equipmentData.category ? types[equipmentData.category] : null);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  useEffect(() => {
    if (equipmentData.category) {
      setNames(types[equipmentData.category] ?? null);
    }
  }, [equipmentData.category]);

  const handleSave = async () => {
    if (equipmentData) {
      await dispatch(addEquipment({ equipment: equipmentData, propertyId: selectedProperty?.propertyId }));
      setAddNew(false);
    }
  };

  const handleChange = (key: string, value: string) => {
    let newValue: string | Date = value;
    if (key === 'purchaseDate') {
      newValue = new Date(`${value}T23:23:59`);
    }
    setEquipmentData({ ...equipmentData, [key]: newValue });
  };

  const handleFileSelection = (fileList: FileList | null) => {
    const { documents: stateDocuments } = equipmentData;
    if (fileList && fileList.length > 0) {
      const documents: DocumentFile[] = [];
      for (let i = 0; i < fileList.length; i += 1) {
        documents.push({
          file: fileList[i],
          description: null,
          date: new Date().toString(),
          name: fileList[i].name
        });
      }
      setEquipmentData({
        ...equipmentData,
        documents: stateDocuments ? [...stateDocuments, ...documents] : documents
      });
    }
  };

  const handleNameChange = (value: string, file: File | null) => {
    if (equipmentData.documents) {
      const newDocuments = equipmentData.documents.map(document => (
        document.file?.name === file?.name ? { ...document, name: value } : document
      ));
      setEquipmentData({
        ...equipmentData,
        documents: newDocuments
      });
    }
  };

  const handleDescription = (value: string, file: File | null) => {
    if (equipmentData.documents) {
      const newDocuments = equipmentData.documents.map(document => (
        document.file?.name === file?.name ? { ...document, description: value } : document
      ));
      setEquipmentData({
        ...equipmentData,
        documents: newDocuments
      });
    }
  };

  const handleDocumentDelete = (document: DocumentFile) => {
    const documents = equipmentData.documents?.filter(doc => doc.file?.name !== document.file?.name);
    setEquipmentData({
      ...equipmentData,
      documents: documents || []
    });
  };

  const handleUpdate = async () => {
    setEdit(false);
    if (equipmentData && selectedProperty?.propertyId) {
      await dispatch(updateEquipment({
        newEquipment: equipmentData,
        equipmentId: selectedEquipment?.equipmentId,
        oldDocuments: selectedEquipment?.documents,
        propertyId: selectedProperty?.propertyId
      }));
    }
    dispatch(updateSelectedEquipment(undefined));
  };

  const handleDelete = () => {
    if (selectedEquipment?.equipmentId) {
      const equipmentDocs = docs.filter(doc => selectedEquipment.documents?.includes(doc.documentId));
      dispatch(deleteEquipment({ equipmentId: selectedEquipment.equipmentId, docs: equipmentDocs }));
      setEdit(false);
    }
  };

  return (
    <FormGroup className="form-container">
      <Box className="form-content-container">
        <TextField
          className="form-field"
          label={t('equipment.category')}
          InputLabelProps={{ shrink: true }}
          onChange={({ target }) => handleChange('category', target.value)}
          value={equipmentData.category || ''}
          select
          required
        >
          {Object.values(EquipmentCategory)
            .map(value => <MenuItem value={value} key={value}>{t(`equipment.${value}`)}</MenuItem>)}
        </TextField>
        <Autocomplete
          freeSolo
          className="form-field"
          options={names ? names.map(name => t(`equipmentName.${name}`)) : []}
          value={selectedName ?? ''}
          onInputChange={(_event, newValue) => {
            setSelectedName(newValue);
            handleChange('name', newValue);
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          renderInput={params => <TextField {...params} required InputLabelProps={{ shrink: true }} label={t('equipment.name')} />}
        />
        <TextField
          className="form-field"
          label={t('equipment.location')}
          type="text"
          defaultValue={equipmentData.location || ''}
          InputLabelProps={{ shrink: true }}
          onChange={({ target }) => handleChange('location', target.value)}
          required
        />
        <TextField
          className="form-field"
          label={t('equipment.purchased')}
          type="date"
          defaultValue={equipmentData && equipmentData.purchaseDate && dateToFormFormat(equipmentData.purchaseDate)}
          InputLabelProps={{ shrink: true }}
          onChange={({ target }) => handleChange('purchaseDate', target.value)}
          required
        />
        <TextField
          className="form-field"
          label={t('equipment.trademark')}
          type="text"
          defaultValue={equipmentData.trademark || ''}
          InputLabelProps={{ shrink: true }}
          onChange={({ target }) => handleChange('trademark', target.value)}
        />
        <TextField
          className="form-field"
          label={t('equipment.model')}
          type="text"
          defaultValue={equipmentData.model || ''}
          InputLabelProps={{ shrink: true }}
          onChange={({ target }) => handleChange('model', target.value)}
        />
        <TextField
          className="form-field"
          label={t('equipment.serialnumber')}
          type="text"
          defaultValue={equipmentData.serialnumber || ''}
          InputLabelProps={{ shrink: true }}
          onChange={({ target }) => handleChange('serialnumber', target.value)}
        />
        <AddedDocuments
          acceptedFiles="image/*,application/pdf"
          documents={equipmentData?.documents ?? []}
          handleSelectFile={handleFileSelection}
          handleNameChange={handleNameChange}
          handleDescription={handleDescription}
          handleDelete={handleDocumentDelete}
        />
      </Box>
      {edit ? (
        <Box className="property-form-buttons">
          <FormButton handleSave={handleUpdate} formData={equipmentData} requiredFields={requiredFields} />
          <Button className="delete-button" onClick={() => setShowConfirm(true)}>{t('button.delete')}</Button>
        </Box>
      ) : (
        <FormButton handleSave={handleSave} formData={equipmentData} requiredFields={requiredFields} />
      )}
      {showConfirm && (
        <ConfirmContainer
          text={t('equipment.confirmDelete')}
          onCancel={() => setShowConfirm(false)}
          confirmButton={{
            text: t('button.delete'),
            onConfirm: handleDelete
          }}
        />
      )}
    </FormGroup>
  );
};
