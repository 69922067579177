import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TableCell, TableRow, Typography } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {
  Loading,
  PrimaryContent,
  Table
} from '../../components';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { dateToTableFormat } from '../../utils';
import {
  addLink, fetchLinks, selectLinks, selectLoading
} from './permissionSlice';
import { selectSelectedProperty } from '../property/propertySlice';

export const PermissionPage = () => {
  const { t } = useTranslation();

  const loading = useAppSelector(selectLoading);
  const links = useAppSelector(selectLinks);
  const selectedProperty = useAppSelector(selectSelectedProperty);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedProperty?.propertyId) {
      dispatch(fetchLinks(selectedProperty?.propertyId));
    }
  }, []);

  const handleSave = () => {
    const today = new Date();
    const nextMonth = new Date(today);
    nextMonth.setMonth(today.getMonth() + 1);

    if (selectedProperty?.propertyId) {
      dispatch(addLink({
        propertyId: selectedProperty?.propertyId,
        permission: {},
        expirationDate: nextMonth.toString()
      }));
    }
  };

  if (loading) {
    return (
      <PrimaryContent title={t('common.permissions')}>
        <Loading />
      </PrimaryContent>
    );
  }

  return (
    <PrimaryContent
      title={t('common.permissions')}
      subTitle={t('common.permissionInfo')}
      button={{
        handleClick: () => handleSave(),
        text: t('common.createANewLink'),
        icon: <AddOutlinedIcon />
      }}
    >
      {links.length ? (
        <Table
          tableHeaders={[
            t('common.link'),
            t('common.expirationDate')
          ]}
        >
          {links.map(link => (
            <TableRow key={link.linkId}>
              <TableCell>{`${window.location.href}/linkki/?linkId=${link.linkId}`}</TableCell>
              <TableCell>{dateToTableFormat(link.expirationDate)}</TableCell>
            </TableRow>
          ))}
        </Table>
      ) : <Typography>{t('common.noLinks')}</Typography>}
    </PrimaryContent>
  );
};
