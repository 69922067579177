import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormGroup,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  Autocomplete
} from '@mui/material';
import { Loading } from '../loading/Loading';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  addMaintenance,
  updateMaintenance,
  selectSelectedMaintenance,
  updateSelectedMaintenance,
  selectMaintenancesLoading
} from '../../pages/maintenance/maintenanceSlice';
import { AddedDocuments } from './AddedDocuments';
import {
  Maintenance,
  MaintenanceFormModel,
  maintenanceInterval,
  MaintenanceType,
  requiredFields
} from '../../models/maintenance';
import { dateToFormFormat } from '../../utils';
import './styles.css';
import { selectSelectedProperty } from '../../pages/property/propertySlice';
import { DocumentFile } from '../../models/document';
import { selectDocumentsLoading } from '../../pages/document/documentSlice';
import { FormButton } from '../buttons/FormButton';

interface MaintenanceFormProps {
  setAddNew: (value: boolean) => void
  edit?: boolean;
  setEdit: (value: boolean) => void
}

export const MaintenanceForm = ({ edit, setEdit, setAddNew }: MaintenanceFormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedMaintenance = useAppSelector(selectSelectedMaintenance);
  const maintenanceLoading = useAppSelector(selectMaintenancesLoading);
  const documentsLoading = useAppSelector(selectDocumentsLoading);
  const selectedProperty = useAppSelector(selectSelectedProperty);
  const isEditing = edit && selectedMaintenance;

  const [maintenanceData, setMaintenanceData] = useState<MaintenanceFormModel>({
    maintenanceType: isEditing ? selectedMaintenance.maintenanceType : null,
    maintenanceDate: new Date().toString(),
    description: isEditing ? selectedMaintenance.description : null,
    nextMaintenance: isEditing ? selectedMaintenance.nextMaintenance : null,
    reminder: isEditing ? selectedMaintenance.reminder || false : false,
    documents: null
  });

  useEffect(() => {
    if (edit !== undefined && !edit) {
      dispatch(updateSelectedMaintenance(undefined));
    }
  }, [edit]);

  const handleChange = (key: keyof Maintenance, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newValue: string | boolean = event.target.value;
    if (key === 'reminder') {
      newValue = (event as ChangeEvent<HTMLInputElement>).target.checked;
    }
    setMaintenanceData({ ...maintenanceData, [key]: newValue });
  };

  const handleSelectChange = (key: string, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (key === 'nextMaintenance') {
      const nextMaintenance = new Date(
        new Date().setMonth(
          new Date().getMonth() + Number(event.target.value)
        )
      );
      setMaintenanceData({ ...maintenanceData, nextMaintenance: nextMaintenance.toString() });
    } else {
      setMaintenanceData({ ...maintenanceData, [key]: event.target.value });
    }
  };

  const handleFileSelection = (fileList: FileList | null) => {
    const { documents: stateDocuments } = maintenanceData;
    if (fileList && fileList.length > 0) {
      const documents: DocumentFile[] = [];
      for (let i = 0; i < fileList.length; i += 1) {
        documents.push({
          file: fileList[i],
          description: null,
          date: new Date().toString(),
          name: fileList[i].name
        });
      }
      setMaintenanceData({
        ...maintenanceData,
        documents: stateDocuments ? [...stateDocuments, ...documents] : documents
      });
    }
  };

  const handleDelete = (document: DocumentFile) => {
    const documents = maintenanceData.documents?.filter(doc => doc.file?.name !== document.file?.name);
    setMaintenanceData({
      ...maintenanceData,
      documents: documents || []
    });
  };

  const handleSave = async () => {
    if (maintenanceData) {
      await dispatch(addMaintenance({ maintenance: maintenanceData, propertyId: selectedProperty?.propertyId }));
    }
    setAddNew(false);
  };

  const handleUpdate = async () => {
    if (maintenanceData && selectedProperty?.propertyId) {
      await dispatch(updateMaintenance({
        newMaintenance: maintenanceData,
        maintenanceId: selectedMaintenance?.maintenanceId,
        oldDocuments: selectedMaintenance?.documents,
        propertyId: selectedProperty?.propertyId
      }));
    }
    setEdit(false);
  };

  const handleNameChange = (value: string, file: File | null) => {
    if (maintenanceData.documents) {
      const newDocuments = maintenanceData.documents.map(document => (
        document.file?.name === file?.name ? { ...document, name: value } : document
      ));
      setMaintenanceData({
        ...maintenanceData,
        documents: newDocuments
      });
    }
  };

  const handleDescription = (value: string, file: File | null) => {
    if (maintenanceData.documents) {
      const newDocuments = maintenanceData.documents.map(document => (
        document.file?.name === file?.name ? { ...document, description: value } : document
      ));
      setMaintenanceData({
        ...maintenanceData,
        documents: newDocuments
      });
    }
  };

  const formatMaintInterval = () => maintenanceInterval
    .map(value => {
      let text = `${value} ${t('dateTime.month')}`;
      if (value > 1 && value < 12) {
        text = `${value} ${t('dateTime.months')}`;
      } else if (value === 12) {
        text = `${value / 12} ${t('dateTime.year')}`;
      } else if (value > 12) {
        text = `${value / 12} ${t('dateTime.years')}`;
      }
      return <MenuItem value={value} key={text}>{text}</MenuItem>;
    });

  const formatNextMaintenance = (nextDate: string | null, maintenanceDate: string | undefined) => {
    if (!nextDate || !maintenanceDate) {
      return '';
    }
    const nextDateObject = new Date(nextDate);
    const maintenanceDateObject = new Date(maintenanceDate);
    return (nextDateObject.getMonth() - maintenanceDateObject.getMonth()
      + (12 * (nextDateObject.getFullYear() - maintenanceDateObject.getFullYear())));
  };

  if (maintenanceLoading || documentsLoading) {
    return <Loading />;
  }

  return (
    <FormGroup className="form-container">
      <Box className="form-content-container">
        <Autocomplete
          freeSolo
          className="form-field"
          options={Object.values(MaintenanceType).map(option => t(`maintenance.${option}`))}
          value={maintenanceData?.maintenanceType ?? ''}
          onInputChange={(_event, newValue) => {
            setMaintenanceData({
              ...maintenanceData,
              maintenanceType: newValue
            });
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          renderInput={params => <TextField {...params} required InputLabelProps={{ shrink: true }} label={t('maintenance.maintenanceType')} />}
        />
        <TextField
          label={t('dateTime.date')}
          type="date"
          className="form-field"
          value={maintenanceData?.maintenanceDate ? dateToFormFormat(maintenanceData.maintenanceDate) : ''}
          InputLabelProps={{ shrink: true }}
          onChange={evt => handleChange('maintenanceDate', evt)}
          disabled
        />
        <TextField
          label={t('common.description')}
          type="text"
          className="form-field"
          InputLabelProps={{ shrink: true }}
          onChange={evt => handleChange('description', evt)}
          value={maintenanceData?.description ?? ''}
          multiline
          required
        />
        <TextField
          label={t('maintenance.nextMaintenance')}
          select
          className="form-field"
          onChange={evt => handleSelectChange('nextMaintenance', evt)}
          value={formatNextMaintenance(maintenanceData?.nextMaintenance, maintenanceData?.maintenanceDate)}
          InputLabelProps={{ shrink: true }}
          required
        >
          {formatMaintInterval()}
        </TextField>
        <FormControlLabel
          className="form-control-label"
          label={t('maintenance.reminder')}
          labelPlacement="start"
          control={
            <Checkbox onChange={evt => handleChange('reminder', evt)} checked={maintenanceData && maintenanceData.reminder} />
          }
        />
        <AddedDocuments
          acceptedFiles="image/*,application/pdf"
          documents={maintenanceData?.documents || []}
          handleSelectFile={handleFileSelection}
          handleNameChange={handleNameChange}
          handleDescription={handleDescription}
          handleDelete={handleDelete}
        />
      </Box>
      {edit ? (
        <Box display="flex">
          <FormButton handleSave={handleUpdate} formData={maintenanceData} requiredFields={requiredFields} />
        </Box>
      ) : (
        <FormButton handleSave={handleSave} formData={maintenanceData} requiredFields={requiredFields} />
      )}
    </FormGroup>
  );
};
