import {
  ChangeEvent,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  Box, Button, IconButton, Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { Property } from '../../models/property';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { updateProperty } from './propertySlice';
import generatePreSignedUrl from '../../aws/api/generatePreSignedUrl';
import { selectDocuments } from '../document/documentSlice';

interface PropertyImageProps {
  selectedProperty?: Property;
}

export const PropertyImage = ({ selectedProperty }:PropertyImageProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const [url, setUrl] = useState<string>();
  const documents = useAppSelector(selectDocuments);
  const correctImage = documents.find(doc => doc.category === 'property' && doc.documentId === selectedProperty?.documents?.at(0));

  useEffect(() => {
    const fetchUrl = async () => {
      setUrl(correctImage ? await generatePreSignedUrl(correctImage.s3Key) : '');
    };

    fetchUrl();
  }, [correctImage]);

  const handleSelectImage = (images: FileList | null) => {
    if (selectedProperty && images && images.length > 0) {
      dispatch(updateProperty({
        property: selectedProperty,
        image: {
          date: new Date().toString(),
          description: null,
          category: 'property',
          file: images[0],
          name: images[0].name
        }
      }));
    }
  };

  return (
    <Box className="property-image-container" position="relative">
      {url ? (
        <>
          <Tooltip title={t('button.edit')}>
            <IconButton
              style={{
                position: 'absolute', top: 5, right: 5, background: '#fff'
              }}
              onClick={() => inputRef?.current?.click()}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <input
            ref={inputRef}
            type="file"
            accept="image/*"
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleSelectImage(event.target.files)}
            hidden
          />
        </>
      ) : (
        <>
          <Button
            className="basic-button"
            style={{ margin: '0 auto' }}
            onClick={() => inputRef?.current?.click()}
          >
            {t('common.addImage')}
          </Button>
          <input
            ref={inputRef}
            type="file"
            accept="image/*"
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleSelectImage(event.target.files)}
            hidden
          />
        </>
      )}
      {url && (
        <img
          className="property-image"
          src={url}
          alt="Property"
        />
      )}
    </Box>

  );
};
