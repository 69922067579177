import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import type { RootState } from '../../store';
import API from '../../api/api';
import { Construction, CreateConstruction, UpdateConstruction } from '../../models/construction';
import { Error } from '../../models/common';
import { generateDocumentIds, parseError } from '../../utils';
import { updateError } from '../../app/appSlice';
import { fetchDocuments } from '../document/documentSlice';

export const fetchConstructionData = createAsyncThunk<Construction[], string | undefined, { rejectValue: Error }>(
  'construction/fetchConstructionData',
  async (propertyId, { rejectWithValue }) => {
    try {
      const response: Construction[] = await API.get(`constructions/${propertyId}`);
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchConstruction = createAsyncThunk<Construction, string, { rejectValue: Error }>(
  'construction/fetchConstruction',
  async (constructionId, { rejectWithValue }) => {
    try {
      const response: Construction = await API.get(`construction/${constructionId}`);
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const addConstruction = createAsyncThunk<Construction | undefined, CreateConstruction, { rejectValue: Error }>(
  'construction/addConstruction',
  async ({ construction, propertyId }, { rejectWithValue, dispatch, getState }) => {
    try {
      let documents = null;
      if (propertyId) {
        documents = construction.documents ? await generateDocumentIds(construction.documents, propertyId) : null;
      }
      const state = getState();
      // @ts-ignore
      if (state?.permission?.linkUser) {
        // @ts-ignore
        const { linkUser } = state.permission;
        await API.post(
          `link/construction/${linkUser.propertyId}/${linkUser.linkId}`,
          {
            ...construction,
            propertyId: linkUser.propertyId,
            changeLog: construction.changeLog,
            documents: construction.documents ? (
              await generateDocumentIds(construction.documents, linkUser.propertyId, linkUser.linkId)
            ) : null
          }
        );
        return undefined;
      }
      const response: Construction = await API.post(
        'construction',
        {
          ...construction,
          propertyId,
          changeLog: construction.changeLog,
          documents
        }
      );
      // TODO: This is heavy weight operation
      dispatch(fetchDocuments(propertyId));
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const updateConstruction = createAsyncThunk<Construction | undefined, UpdateConstruction, { rejectValue: Error }>(
  'construction/updateConstruction',
  async ({
    construction, documents, changeLog, propertyId
  }, { rejectWithValue, dispatch, getState }) => {
    try {
      let allDocuments: (string | undefined)[] = construction.documents;
      if (documents) {
        const docIds = await generateDocumentIds(documents, propertyId);
        allDocuments = construction.documents ? [...construction.documents, ...docIds] : docIds;
      }
      let newChangeLog = construction.changeLog;
      if (changeLog && Array.isArray(changeLog)) {
        newChangeLog = [...construction.changeLog, ...changeLog];
      } else if (changeLog) {
        newChangeLog = [...construction.changeLog, changeLog];
      }
      const renovationWithoutId = (({ constructionId, ...o }) => o)({
        ...construction, documents: allDocuments, changeLog: newChangeLog
      });
      const state = getState();
      // @ts-ignore
      if (state?.permission?.linkUser) {
        // @ts-ignore
        const { linkUser } = state.permission;
        await API.put(
          `link/construction/${linkUser.propertyId}/${linkUser.linkId}/${construction.constructionId}`,
          renovationWithoutId
        );
        return undefined;
      }
      const response: Construction = await API.put(`construction/${construction.constructionId}`, renovationWithoutId);
      // TODO: This is heavy weight operation
      dispatch(fetchDocuments(propertyId));
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const deleteConstructionData = createAsyncThunk<string, string, { rejectValue: Error }>(
  'construction/deleteConstructionData',
  async (constructionId, { rejectWithValue }) => {
    try {
      await API.delete(`construction/${constructionId}`);
      return constructionId;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

// Define a type for the slice state
interface ConstructionState {
  constructions: Construction[];
  loading: boolean;
}

// Define the initial state using that type
const initialState: ConstructionState = {
  constructions: [],
  loading: false
};

export const constructionSlice = createSlice({
  name: 'construction',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchConstructionData.pending, state => {
        state.loading = true;
      })
      .addCase(fetchConstructionData.fulfilled, (state, action) => {
        state.loading = false;
        state.constructions = action.payload;
      })
      .addCase(fetchConstructionData.rejected, (state, action) => {
        state.loading = false;
        updateError(action.payload);
      })
      .addCase(fetchConstruction.pending, state => {
        state.loading = true;
      })
      .addCase(fetchConstruction.fulfilled, state => {
        state.loading = false;
      })
      .addCase(fetchConstruction.rejected, (state, action) => {
        state.loading = false;
        updateError(action.payload);
      })
      .addCase(addConstruction.pending, state => {
        state.loading = true;
      })
      .addCase(addConstruction.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.constructions.push(action.payload);
        }
        toast.success(i18next.t('construction.addedSuccessfully'));
      })
      .addCase(addConstruction.rejected, (state, action) => {
        state.loading = false;
        updateError(action.payload);
      })
      .addCase(updateConstruction.pending, state => {
        state.loading = true;
      })
      .addCase(updateConstruction.fulfilled, (state, action) => {
        if (action.payload?.constructionId) {
          const updatedIndex = state.constructions
            .findIndex(({ constructionId }) => constructionId === action.payload!.constructionId);

          if (updatedIndex !== -1) {
            state.constructions[updatedIndex] = action.payload;
          }
        }
        state.loading = false;
        toast.success(i18next.t('construction.updatedSuccessfully'));
      })
      .addCase(updateConstruction.rejected, (state, action) => {
        state.loading = false;
        updateError(action.payload);
      })
      .addCase(deleteConstructionData.pending, state => {
        state.loading = true;
      })
      .addCase(deleteConstructionData.fulfilled, (state, action) => {
        const removedIndex = state.constructions.findIndex(({ constructionId }) => constructionId === action.payload);

        if (removedIndex !== -1) {
          state.constructions.splice(removedIndex, 1);
        }
        state.loading = false;
      })
      .addCase(deleteConstructionData.rejected, (state, action) => {
        state.loading = false;
        updateError(action.payload);
      });
  }
});

export const selectConstructions = (state: RootState) => state.construction.constructions;
export const selectConstructionLoading = (state: RootState) => state.construction.loading;

export default constructionSlice.reducer;
