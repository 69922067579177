import { ChangeEvent, useRef } from 'react';
import {
  Box, Button, TableRow, TableCell, TextField, Typography, IconButton, Tooltip
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useTranslation } from 'react-i18next';
import { Table } from '../table/Table';
import { useAppSelector } from '../../hooks';
import { selectIsMobile } from '../../app/appSlice';
import { DocumentFile } from '../../models/document';

interface AddedDocumentsProps {
  acceptedFiles: 'image/*,application/pdf' | 'application/pdf' | 'image/*';
  documents: DocumentFile[];
  handleSelectFile: (value: FileList | null) => void;
  handleNameChange: (value: string, file: File | null) => void;
  handleDescription: (value: string, file: File | null) => void;
  handleDelete: (document: DocumentFile) => void;
}

export const AddedDocuments = ({
  acceptedFiles,
  documents,
  handleSelectFile,
  handleNameChange,
  handleDescription,
  handleDelete
}: AddedDocumentsProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const isMobile = useAppSelector(selectIsMobile);

  return (
    <Box className="form-documents-container">
      {documents.length > 0 && (
        <Box>
          <Typography>{t('document.addedDocuments')}</Typography>
          {isMobile ? documents.map(document => (
            <Box className="mobile-documents-container" key={`${document.file?.name}${document.date}`}>
              <Box className="mobile-documents-container-header">
                <IconButton className="icon-button" onClick={() => handleDelete(document)}>
                  <DeleteOutlineOutlinedIcon fontSize="small" />
                </IconButton>
              </Box>
              <TextField
                className="form-field"
                type="text"
                value={document.name != null ? document.name : document.file?.name}
                placeholder={t('common.name')}
                variant="standard"
                size="small"
                inputProps={{ maxLength: 160 }}
                onChange={({ target }) => handleNameChange(target.value, document.file)}
              />
              <TextField
                className="form-field"
                type="text"
                value={document.description}
                placeholder={t('common.addDescription')}
                variant="standard"
                size="small"
                maxRows={2}
                inputProps={{ maxLength: 160 }}
                onChange={({ target }) => handleDescription(target.value, document.file)}
                multiline
              />
            </Box>
          )) : (
            <Table tableHeaders={[t('common.name'), t('common.description'), '']}>
              {documents.map(document => (
                <TableRow key={document.file?.name}>
                  <TableCell>
                    <TextField
                      className="cell-form-field"
                      type="text"
                      value={document.name != null ? document.name : document.file?.name}
                      placeholder={t('common.name')}
                      variant="standard"
                      size="small"
                      maxRows={2}
                      inputProps={{ maxLength: 160 }}
                      onChange={({ target }) => handleNameChange(target.value, document.file)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      className="cell-form-field"
                      type="text"
                      value={document.description || ''}
                      placeholder={t('common.addDescription')}
                      variant="standard"
                      size="small"
                      inputProps={{ maxLength: 160 }}
                      onChange={({ target }) => handleDescription(target.value, document.file)}
                      multiline
                    />
                  </TableCell>
                  <TableCell>
                    <Tooltip title={t('button.delete')}>
                      <IconButton onClick={() => handleDelete(document)}>
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          )}
        </Box>
      )}
      <Button className="basic-button" onClick={() => inputRef.current?.click()}>
        {acceptedFiles === 'image/*' ? t('common.addImage') : t('button.addFile')}
      </Button>
      <input
        ref={inputRef}
        type="file"
        accept={acceptedFiles}
        onChange={(event: ChangeEvent<HTMLInputElement>) => handleSelectFile(event.target.files)}
        hidden
        multiple
      />
    </Box>
  );
};
