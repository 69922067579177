import { Button } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useTranslation } from 'react-i18next';
import { downloadFile } from '../../utils';

interface DownloadFileButtonProps {
  url: string;
  fileName: string;
}

export const DownloadFileButton = ({ url, fileName }: DownloadFileButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button
      startIcon={<FileDownloadOutlinedIcon />}
      className="document-button"
      onClick={() => downloadFile(url, fileName)}
    >
      {t('button.download')}
    </Button>
  );
};
