import { useEffect, useRef, useState } from 'react';
import { Document as PDFDocument, Page } from 'react-pdf';
import { Box, Typography } from '@mui/material';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { Loading } from '../loading/Loading';
import './Pdf.css';
import generatePreSignedUrl from '../../aws/api/generatePreSignedUrl';
import { DownloadFileButton } from '../buttons/DownloadFileButton';
import { dateToTableFormat } from '../../utils';

interface PdfProps {
  s3Key: string;
  name: string;
  description?: string;
  date: string;
  fileName: string;
}

export const Pdf = ({
  s3Key, name, description, date, fileName
}: PdfProps) => {
  const [pages, setPages] = useState<number>();
  const [width, setWidth] = useState<number>();
  const ref = useRef<HTMLInputElement>(null);
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref.current]);

  useEffect(() => {
    const fetchUrl = async () => {
      setUrl(await generatePreSignedUrl(s3Key));
    };

    fetchUrl();
  }, [s3Key]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPages(numPages);
  };

  if (!url) {
    return <Box />;
  }

  return (
    <Box className="pdf-container">
      <Box className="document-container" ref={ref}>
        <PDFDocument
          file={{ url: url || '' }}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<Loading />}
        >
          {Array.from(new Array(pages), (_, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={width}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              loading={<div />}
            />
          ))}
        </PDFDocument>
      </Box>
      <Box className="description-container">
        <Typography sx={{ fontWeight: 'bold' }}>
          {name === fileName ? '' : (
            <span style={{ marginRight: '8px' }}>
              {fileName}
            </span>
          )}
          {name === fileName ? '' : '|'}
          <span style={{ marginLeft: '8px' }}>{dateToTableFormat(date)}</span>
        </Typography>
        {description && (
          <Typography sx={{ textAlign: 'justify' }}>
            <FormatQuoteIcon fontSize="small" color="disabled" />
            {description}
            <FormatQuoteIcon fontSize="small" color="disabled" />
          </Typography>
        )}
      </Box>
      <DownloadFileButton url={url} fileName={fileName} />
    </Box>
  );
};
