/* eslint-disable no-restricted-syntax */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PDFViewer, pdf
} from '@react-pdf/renderer';
import {
  Box,
  Button,
  Checkbox,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PDFMerger from 'pdf-merger-js/browser';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  BrochureDocument,
  ModalContainer,
  PrimaryContent,
  SecondaryContent,
  Table,
  Tabs
} from '../../components';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectIsMobile } from '../../app/appSlice';
import { selectSelectedProperty } from '../property/propertySlice';
import { fetchDocuments, selectDocuments, selectDocumentsLoading } from '../document/documentSlice';
import { fetchRenovations, selectRenovations, selectRenovationsLoading } from '../renovation/renovationSlice';
import { Renovation } from '../../models/renovation';
import { Document, DocumentCategory } from '../../models/document';
import { TabItem } from '../../models/components/tab';
import { dateToTableFormat } from '../../utils';
import './BrochurePage.css';
import generatePreSignedUrl from '../../aws/api/generatePreSignedUrl';

export const BrochurePage = () => {
  const { t } = useTranslation();
  const [imageUrl, setImageUrl] = useState<string>();
  const [selectedRenovations, setSelectedRenovations] = useState<Record<number, { id: string, renovation: string }[]>>();
  const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>('renovation');
  const [previewPdf, setPreviewPdf] = useState<boolean>(false);

  const isMobile = useAppSelector(selectIsMobile);
  const selectedProperty = useAppSelector(selectSelectedProperty);
  const documents = useAppSelector(selectDocuments);
  const renovations = useAppSelector(selectRenovations);
  const documentsLoading = useAppSelector(selectDocumentsLoading);
  const renovationsLoading = useAppSelector(selectRenovationsLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchDocuments(selectedProperty?.propertyId));
    dispatch(fetchRenovations(selectedProperty?.propertyId));
  }, []);

  const doc = (
    <BrochureDocument
      property={selectedProperty!}
      imageUrl={imageUrl}
      renovations={selectedRenovations}
    />
  );

  const tabItems: TabItem[] = [
    { label: t('common.renovations'), value: 'renovation' },
    { label: t('common.documents'), value: 'documents' }
  ];

  useEffect(() => {
    const fetchUrl = async () => {
      const correctImage = documents.find(document => (
        document.category === 'property' && document.documentId === selectedProperty?.documents?.at(0)
      ));
      if (selectedProperty && correctImage) {
        setImageUrl(await generatePreSignedUrl(correctImage.s3Key));
      }
    };

    fetchUrl();
  }, [selectedProperty, documents]);

  const handleSelectionChange = (checked: boolean, selectedRenovation: Renovation) => {
    const year = new Date(selectedRenovation.startDate).getFullYear();
    const { renovationId, renovation } = selectedRenovation;
    if (!selectedRenovations) {
      setSelectedRenovations({ [year]: [{ id: renovationId, renovation }] });
      return;
    }

    const tempData: Record<number, { id: string, renovation: string }[]> = { ...selectedRenovations };
    const index = tempData[year]?.findIndex(({ id }) => id === renovationId);

    if (checked) {
      if (!tempData[year]) {
        tempData[year] = [{ id: renovationId, renovation }];
      }
      if (index === -1) {
        tempData[year].push({ id: renovationId, renovation });
      }
    } else if (!checked && tempData[year] && index !== -1) {
      tempData[year].splice(index, 1);
      if (tempData[year].length === 0) {
        delete tempData[year];
      }
    }
    setSelectedRenovations(tempData);
  };

  const handleDocumentChange = (document: Document) => {
    const index = selectedDocuments.findIndex(value => value === document);
    if (index !== -1) {
      const filtered = selectedDocuments.filter(value => value !== document);
      setSelectedDocuments(filtered);
    } else {
      setSelectedDocuments([...selectedDocuments, document]);
    }
  };

  const getFileBlobs = async (): Promise<Blob[] | undefined> => {
    try {
      return await Promise.all(selectedDocuments.map(async document => {
        const url = await generatePreSignedUrl(document.s3Key);
        if (url) {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/octet-stream',
              'Content-Disposition': 'attachment'
            }
          });
          const blob = await response.blob();
          return new Blob([blob], { type: 'application/pdf' });
        }
        return new Blob();
      }));
    } catch (err) {
      return undefined;
    }
  };

  const onDowload = async () => {
    const asPdf = pdf();
    asPdf.updateContainer(doc);
    const appendixBlob = await asPdf.toBlob();

    const merger = new PDFMerger();
    await merger.add(appendixBlob);

    const fileBlobs = await getFileBlobs();
    if (fileBlobs) {
      await Promise.all(fileBlobs.map(async file => { await merger.add(file); }));
    }

    const mergedPdf = await merger.saveAsBlob();
    const url = URL.createObjectURL(mergedPdf);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${t('common.brochure')}.pdf`;
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const isRenovationSelected = (id: string) => {
    for (const key in selectedRenovations) {
      if (Object.prototype.hasOwnProperty.call(selectedRenovations, key)) {
        const numKey = Number(key);
        if (selectedRenovations[numKey].some(item => item.id === id)) {
          return true;
        }
      }
    }
    return false;
  };

  const renovationList = (
    <Table
      tableHeaders={[
        t('common.choose'),
        t('common.renovation'),
        t('dateTime.startDate')
      ]}
    >
      {renovations.map(renovation => (
        <TableRow className="renovation-table-row" key={renovation.renovationId}>
          <TableCell>
            <Checkbox
              checked={isRenovationSelected(renovation.renovationId)}
              onChange={({ target }) => handleSelectionChange(target.checked, renovation)}
            />
          </TableCell>
          <TableCell>{renovation.renovation}</TableCell>
          <TableCell>{dateToTableFormat(renovation.startDate)}</TableCell>
        </TableRow>
      ))}
    </Table>
  );

  const documentList = (
    <Table
      tableHeaders={[
        t('common.choose'),
        t('common.name'),
        t('dateTime.date')
      ]}
    >
      {documents.filter(document => (// @ts-ignore
        document.type === 'pdf' && Object.values(DocumentCategory).includes(document.category)
      )).map(document => (
        <TableRow className="renovation-table-row" key={document.documentId}>
          <TableCell>
            <Checkbox
              checked={selectedDocuments.some(selectedDocument => selectedDocument.documentId === document.documentId)}
              onChange={() => handleDocumentChange(document)}
            />
          </TableCell>
          <TableCell>{document.name}</TableCell>
          <TableCell>{dateToTableFormat(document.date)}</TableCell>
        </TableRow>
      ))}
    </Table>
  );

  return isMobile ? (
    <PrimaryContent
      title={t('common.brochure')}
      isLoading={documentsLoading || renovationsLoading}
      button={{
        icon: <FileDownloadOutlinedIcon />,
        handleClick: onDowload,
        text: t('button.download')
      }}
    >
      <Box className="document-viewer-container">
        <Tabs
          items={tabItems}
          selectedCategory={selectedTab}
          handleChange={value => setSelectedTab(value)}
        />
        <Box className="mobile-content-container">
          {selectedTab === 'renovation' && renovationList}
          {selectedTab === 'documents' && documentList}
        </Box>
        <Box>
          <Button className="basic-button" onClick={() => setPreviewPdf(!previewPdf)}>{t('button.preview')}</Button>
        </Box>
      </Box>
      <ModalContainer
        title={t('common.preview')}
        open={previewPdf}
        handleClose={() => setPreviewPdf(!previewPdf)}
      >
        <Typography variant="subtitle2" sx={{ fontStyle: 'italic', mb: 2 }}>{t('common.brochureNotice')}</Typography>
        {doc}
      </ModalContainer>
    </PrimaryContent>
  ) : (
    <>
      <SecondaryContent title={t('common.brochure')}>
        <Tabs
          items={tabItems}
          selectedCategory={selectedTab}
          handleChange={value => setSelectedTab(value)}
        />
        <Box>
          {selectedTab === 'renovation' && renovationList}
          {selectedTab === 'documents' && documentList}
          {selectedTab === 'documents' && (
            <Box display="flex" mt="20px" alignItems="center" justifyContent="center">
              <InfoOutlinedIcon />
              <Typography variant="caption" fontStyle="italic">{t('document.selectedDocumentsInDownload')}</Typography>
            </Box>
          )}
        </Box>
      </SecondaryContent>
      <PrimaryContent
        title={t('common.preview')}
        button={{
          text: t('button.download'),
          icon: <FileDownloadOutlinedIcon />,
          handleClick: onDowload
        }}
      >
        <Box className="document-viewer-container">
          <PDFViewer className="pdf-viewer" showToolbar={false}>
            {doc}
          </PDFViewer>
        </Box>
      </PrimaryContent>
    </>
  );
};
